import $ from 'jquery';

$(function() {
	$('.selecter__list').on('show',function(){
		$(this).parent().addClass('selecter_open');
	}).on('hide',function(){
		$(this).parent().removeClass('selecter_open');
	}).on('toggle',function(){
		if($(this).parent().hasClass('selecter_open')){
			$(this).trigger('hide');
		}
		else $(this).trigger('show');
	});
	
	$('.selecter__title').click(function(){
		$(this).next().trigger('toggle');
		return false;
	});

	$(document).click(function(e){
		if ($(e.target).closest('.selecter__title').length)
		return;
		$('.selecter__list').trigger('hide');
		e.stopPropagation();
	});
	
	$('.menu__btn').each(function(){
		$(this).click(function(){
			$(this).parents('.menu').toggleClass('menu_visible');
		});
	});
	
	$('.product__btn_price').each(function(){
		$(this).click(function(){
			var price = $(this).attr('data-price');
			$(this).addClass('product__btn_active');
			$(this).parent().siblings().find('.product__btn_price').removeClass('product__btn_active');
			$('.form__col_price').text(price);
			$('.form__field_price').val(price);
		});
	});
});