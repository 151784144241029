//import Swiper from 'swiper'

var slides = new Swiper('.slides', {
    slidesPerView: 'auto',
	centeredSlides: true,
	spaceBetween: 0,
	loop: true,
	grabCursor: true,
	autoplay: {
		delay: 2500,
	},
});