import $ from 'jquery';

$(function() {
	$('form').each(function(){
		var form = $(this);
		var checkbox = $('.form__checkbox_private',this);
		var btn = $('[type="submit"]',this);
		
		$(btn).on('click', function(){
			if($(checkbox).prop('checked') != true && $(checkbox).hasClass('form__checkbox_private')) { 
				alert('Для отправки сообщения необходимо подтвердить согласие на обработку персональных данных.');
				return false;
			}
			return true;
		});
	});
});